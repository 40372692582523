@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  background-color: var(--color-background-paper);
}

main {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font: inherit;
}

:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

* {
  box-sizing: border-box;
}

:root {
  --header-inner-height: 52px;
}

:root {
  --header-height: var(--header-inner-height);
  --footer-height: 67px;
}

.psaBanner:root {
  --header-height: calc(var(--header-inner-height) * 2);
}

/* On mobile, the banner text is two lines + padding */
@media (max-width: 600px) {
  .psaBanner:root {
    --header-height: calc(var(--header-inner-height) * 2 + 16px);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.illustration-main-fill {
  fill: var(--color-primary-main);
}

.illustration-light-fill {
  fill: var(--color-border-main);
}

.illustration-background-fill {
  fill: var(--color-logo-background);
}

/* Note: a fallback `stroke` property must be on the svg to work */
.illustration-main-stroke {
  stroke: var(--color-primary-main);
}

.illustration-light-stroke {
  stroke: var(--color-border-main);
}

.illustration-background-stroke {
  stroke: var(--color-logo-background);
}

@media (max-width: 600px) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    background: var(--color-background-paper);
  }
}

body.beamerAnnouncementBarTopActive {
  padding-top: 0 !important;
}

#beamerLastPostTitle {
  left: 330px !important;
}

/* This file is generated from the MUI theme colors. Do not edit directly. */

:root {
  --color-text-primary: #121312;
  --color-text-secondary: #a1a3a7;
  --color-text-disabled: #dddee0;
  --color-primary-dark: #3c3c3c;
  --color-primary-main: #121312;
  --color-primary-light: #636669;
  --color-secondary-dark: #0fda6d;
  --color-secondary-main: #efefef;
  --color-secondary-light: #b0ffc9;
  --color-secondary-background: #effff4;
  --color-border-main: #a1a3a7;
  --color-border-light: #dcdee0;
  --color-border-background: #f4f4f4;
  --color-error-dark: #ac2c3b;
  --color-error-main: #ff5f72;
  --color-error-light: #ffb4bd;
  --color-error-background: #ffe6ea;
  --color-success-dark: #028d4c;
  --color-success-main: #00b460;
  --color-success-light: #72f5b8;
  --color-success-background: #f2fff9;
  --color-info-dark: #52bfdc;
  --color-info-main: #5fddff;
  --color-info-light: #b7f0ff;
  --color-info-background: #effcff;
  --color-warning-dark: #cd674e;
  --color-warning-main: #ff8061;
  --color-warning-light: #ffb7a6;
  --color-warning-background: #fff0ed;
  --color-background-default: #f4f4f4;
  --color-background-main: #f4f4f4;
  --color-background-paper: #ffffff;
  --color-background-light: #effff4;
  --color-backdrop-main: #636669;
  --color-logo-main: #121312;
  --color-logo-background: #eeeff0;
  --color-static-main: #121312;
  --space-1: 8px;
  --space-2: 16px;
  --space-3: 24px;
  --space-4: 32px;
  --space-5: 40px;
  --space-6: 48px;
  --space-7: 56px;
  --space-8: 64px;
  --space-9: 72px;
  --space-10: 80px;
  --space-11: 88px;
  --space-12: 96px;
}

[data-theme='dark'] {
  --color-text-primary: #ffffff;
  --color-text-secondary: #636669;
  --color-text-disabled: #636669;
  --color-primary-dark: #efefef;
  --color-primary-main: #fefefe;
  --color-primary-light: #a1a3a7;
  --color-secondary-dark: #636669;
  --color-secondary-main: #ffffff;
  --color-secondary-light: #efefef;
  --color-secondary-background: #303033;
  --color-border-main: #636669;
  --color-border-light: #303033;
  --color-border-background: #121312;
  --color-error-dark: #ac2c3b;
  --color-error-main: #ff5f72;
  --color-error-light: #ffb4bd;
  --color-error-background: #2f2527;
  --color-success-dark: #028d4c;
  --color-success-main: #00b460;
  --color-success-light: #81c784;
  --color-success-background: #1f2920;
  --color-info-dark: #52bfdc;
  --color-info-main: #5fddff;
  --color-info-light: #b7f0ff;
  --color-info-background: #19252c;
  --color-warning-dark: #cd674e;
  --color-warning-main: #ff8061;
  --color-warning-light: #ffb7a6;
  --color-warning-background: #2f2318;
  --color-background-default: #121312;
  --color-background-main: #121312;
  --color-background-paper: #1c1c1c;
  --color-background-light: #1b2a22;
  --color-backdrop-main: #636669;
  --color-logo-main: #ffffff;
  --color-logo-background: #303033;
  --color-static-main: #121312;
}

/* The same as above for the brief moment before JS loads */
@media (prefers-color-scheme: dark) {
  :root:not([data-theme='light']) {
    --color-text-primary: #ffffff;
    --color-text-secondary: #636669;
    --color-text-disabled: #636669;
    --color-primary-dark: #efefef;
    --color-primary-main: #fefefe;
    --color-primary-light: #a1a3a7;
    --color-secondary-dark: #636669;
    --color-secondary-main: #ffffff;
    --color-secondary-light: #efefef;
    --color-secondary-background: #303033;
    --color-border-main: #636669;
    --color-border-light: #303033;
    --color-border-background: #121312;
    --color-error-dark: #ac2c3b;
    --color-error-main: #ff5f72;
    --color-error-light: #ffb4bd;
    --color-error-background: #2f2527;
    --color-success-dark: #028d4c;
    --color-success-main: #00b460;
    --color-success-light: #81c784;
    --color-success-background: #1f2920;
    --color-info-dark: #52bfdc;
    --color-info-main: #5fddff;
    --color-info-light: #b7f0ff;
    --color-info-background: #19252c;
    --color-warning-dark: #cd674e;
    --color-warning-main: #ff8061;
    --color-warning-light: #ffb7a6;
    --color-warning-background: #2f2318;
    --color-background-default: #121312;
    --color-background-main: #121312;
    --color-background-paper: #1c1c1c;
    --color-background-light: #1b2a22;
    --color-backdrop-main: #636669;
    --color-logo-main: #ffffff;
    --color-logo-background: #303033;
    --color-static-main: #121312;
  }
}

:root {
  --onboard-white: var(--color-background-paper);
  --onboard-black: var(--color-text-primary);

  --onboard-primary-1: var(--color-secondary-main);
  --onboard-primary-100: var(--color-secondary-background);
  --onboard-primary-200: var(--color-primary-light);
  --onboard-primary-400: var(--color-primary-light);
  --onboard-primary-500: var(--color-primary-main);
  --onboard-primary-600: var(--color-primary-main);
  --onboard-primary-700: var(--color-secondary-main);

  --onboard-gray-100: var(--color-border-light);
  --onboard-gray-200: var(--color-border-main);
  --onboard-gray-300: var(--color-primary-light);
  --onboard-gray-400: var(--color-primary-main);
  --onboard-gray-500: var(--color-primary-main);
  --onboard-gray-600: var(--color-border-main);
  --onboard-gray-700: var(--color-text-primary);

  --onboard-success-100: var(--color-secondary-background);
  --onboard-success-600: var(--color-secondary-light);
  --onboard-success-700: var(--color-success-dark);

  --onboard-danger-500: var(--color-error-main);
  --onboard-danger-600: var(--color-error-main);
  --onboard-danger-700: var(--color-error-dark);

  --onboard-warning-100: var(--color-error-background);
  --onboard-warning-400: var(--color-error-light);
  --onboard-warning-500: var(--color-error-light);
  --onboard-warning-600: var(--color-error-main);
  --onboard-warning-700: var(--color-error-dark);

  /* var(--color-backdrop-main) + opacity */
  --onboard-modal-backdrop: rgba(99, 102, 105, 0.75);

  --account-select-modal-white: var(--color-background-paper);
  --account-select-modal-black: var(--color-primary-main);
  --account-select-modal-primary-100: var(--color-secondary-background);
  --account-select-modal-primary-200: var(--color-background-light);
  --account-select-modal-primary-300: var(--color-secondary-background);
  --account-select-modal-primary-500: var(--color-secondary-background);
  --account-select-modal-primary-600: var(--color-secondary-light);

  --account-select-primary-300: var(--color-primary-main);
  --account-select-primary-500: var(--color-primary-main);

  --onboard-connect-sidebar-color: var(--color-text-primary);
  --onboard-wallet-button-color: var(--color-text-primary);
  --onboard-wallet-app-icon-border-color: var(--color-border-light);
  --onboard-wallet-app-icon-background-white: #fff;
  --onboard-wallet-app-icon-background-transparent: #fff;
  --onboard-wallet-app-icon-background-light-gray: rgba(255, 255, 255, 0.5);
  --onboard-wallet-button-border-color: var(--color-border-light);
  --onboard-wallet-button-border-radius: 8px;
  --onboard-wallet-button-background-hover: var(--color-background-light);
  --onboard-wallet-button-background-active: var(--color-background-light);
  /* Needs to be brighter than var(--color-primary-main) to compensate for shadow blur */
  --onboard-wallet-button-box-shadow-hover: 0 0 2px #00fd00;

  /* Fonts */
  --onboard-font-family-normal: Inter, sans-serif;
  --onboard-font-family-semibold: Inter, sans-serif;
  --onboard-font-family-light: Inter, sans-serif;
  --account-select-modal-font-family-normal: Inter, sans-serif;
  --account-select-modal-font-family-light: Inter, sans-serif;

  --onboard-modal-border-radius: 8px;
  --onboard-border-radius-1: 8px;
  --onboard-border-radius-2: 8px;
  --onboard-border-radius-3: 8px;

  /* Z-index */
  --onboard-modal-z-index: 1301;
  --onboard-account-select-modal-z-index: 1301;
}

#walletconnect-qrcode-modal {
  padding: 20px !important;
}

#walletconnect-wrapper {
  color: #162d45;
}

#walletconnect-wrapper .walletconnect-modal__footer {
  flex-wrap: wrap;
  gap: 5px;
}

/* Keystone modal */
#kv_sdk_container + .ReactModalPortal > div {
  z-index: 1301 !important;
}

.styles_circle__esIuC {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  margin-left: 0.2em;
}

.styles_icon__60_Sp {
  width: auto;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
}

.styles_container__rxAZ3 {
  display: flex;
  align-items: center;
  gap: 0.5em;
  line-height: 1.4;
}

.styles_avatar__5rCuj {
  flex-shrink: 0;
}

.styles_resizeAvatar__qMqSC,
.styles_resizeAvatar__qMqSC * {
  width: 2.3em !important;
  height: 2.3em !important;
}

.styles_addressRow__52BEb {
  display: flex;
  align-items: center;
  gap: 0.25em;
  white-space: nowrap;
}

.styles_nameRow___G28i {
  overflow: hidden;
}

.styles_mobileAddress__fIwBR {
  display: none;
}

@media (max-width: 600px) {
  .styles_mobileAddress__fIwBR {
    display: inline-block;
  }

  .styles_desktopAddress__FDbLQ {
    display: none;
  }
}

.styles_container__mHx1M {
  display: flex;
  align-items: center;
  gap: var(--space-1);
  justify-content: center;
}

.styles_imageContainer__oJ3d0 {
  display: flex;
  justify-content: center;
}

[data-theme='dark'] .styles_imageContainer__oJ3d0 img[alt*='Trezor'],
[data-theme='dark'] .styles_imageContainer__oJ3d0 img[alt*='Ledger'] {
  filter: invert(100%);
}

@media (max-width: 600px) {
  .styles_buttonContainer__MRAQ3 button {
    font-size: 12px;
  }

  .styles_imageContainer__oJ3d0,
  .styles_walletDetails__ka9_o {
    display: none;
  }
}

.styles_connectedContainer__rcA_X {
  display: flex;
  align-items: center;
}

.styles_buttonContainer__sQ5Ep {
  display: flex;
  align-items: center;
  text-align: left;
  gap: var(--space-1);
}

.styles_popoverContainer__hcs0L {
  padding: var(--space-2);
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2);
  border: 1px solid var(--color-border-light);
}

.styles_addressName__j0AHz {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.styles_rowContainer__FRjxI {
  align-self: stretch;
  margin-left: calc(var(--space-2) * -1);
  margin-right: calc(var(--space-2) * -1);
}

.styles_row__neDZY {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-border-light);
  padding: 4px var(--space-2);
  margin-top: -2px;
}

.styles_row__neDZY:last-of-type {
  border-bottom: 1px solid var(--color-border-light);
}

.styles_pairingDetails__pZ_Ok {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2);
}

@media (max-width: 600px) {
  .styles_buttonContainer__sQ5Ep button {
    font-size: 12px;
  }

  .styles_pairingDetails__pZ_Ok {
    display: none;
  }
}

.styles_circle__egpJg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  background-color: var(--color-background-main);
}

.styles_badge__50Qia .MuiBadge-badge {
  border: 2px solid var(--color-background-paper);
  border-radius: 50%;
  box-sizing: content-box;
}

.styles_button__aAzcJ {
  display: block;
  height: 35px;
}

.styles_indicator__TqCmn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  font-size: 12px;
}

.styles_inlineIndicator__Ec8RB {
  display: inline-block;
  min-width: 70px;
  font-size: 11px;
  line-height: normal;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
}

.styles_select__Yu2wY {
  height: 100%;
}

.styles_select__Yu2wY:after,
.styles_select__Yu2wY:before {
  display: none;
}

.styles_select__Yu2wY *:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.styles_select__Yu2wY .MuiSelect-select {
  padding-right: 40px !important;
  padding-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}

.styles_select__Yu2wY .MuiSvgIcon-root {
  margin-right: var(--space-2);
}

.styles_buttonContainer__pQqJl {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-1);
  height: 100%;
  justify-content: center;
}

.styles_tokenButton__cW5e7 {
  display: flex;
  border-radius: 6px;
  padding: 0px var(--space-1) 0px var(--space-1);
  background-color: var(--color-border-background);
  margin: var(--space-1);
  height: 30px;
  align-items: center;
  justify-content: center;
  gap: var(--space-1);
  margin-left: 0;
  margin-right: 0;
}

.styles_container__e23_N {
  position: fixed;
  top: var(--header-height);
  right: 0;
  z-index: 2000;
}

.styles_row__rqBCu {
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  word-break: break-word;
}

.styles_link__G8QvZ {
  text-decoration: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 0.3em;
}

.styles_container__e23_N details {
  margin-bottom: var(--space-1);
  max-height: 200px;
  overflow: auto;
}

.styles_container__e23_N pre {
  margin: var(--space-1) 0 var(--space-2);
  white-space: pre-wrap;
  color: var(--color-primary-light);
}

.styles_container__e23_N summary {
  text-decoration: underline;
  cursor: pointer;
  list-style: none;
  margin-top: 4px;
}

.styles_container__e23_N summary::-webkit-details-marker {
  display: none;
}

.styles_item__Rt4f8 {
  position: relative;
  padding: 8px 24px;
}

.styles_item__Rt4f8:not(:last-of-type):after {
  content: '';
  background: var(--color-border-background);
  position: absolute;
  bottom: 0;
  left: 24px;
  height: 2px;
  width: calc(100% - 48px);
}

.styles_requiresAction__6vaTj {
  background-color: var(--color-primary-background);
}

.styles_avatar__1E9hA {
  min-width: 42px;
}

.styles_secondaryText__pnOHA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-border-main);
}

.styles_wrapper__nBZVy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 156px;
  color: var(--color-border-main);
}

.styles_scrollContainer__nIgSe {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
}

.styles_bell__xb7SK {
  display: flex;
  justify-content: center;
  padding: 4px;
}

.styles_bell__xb7SK svg path {
  stroke: var(--color-text-primary);
}

.styles_popoverContainer__T6ecL {
  width: 446px;
  border: 1px solid var(--color-border-light);
}

.styles_popoverHeader__AgTc8 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-3);
  border-bottom: 2px solid var(--color-background-main);
}

.styles_popoverFooter__QfBoF {
  padding: var(--space-2);
  display: flex;
  align-items: center;
}

.styles_expandButton__9PoVW {
  box-sizing: border-box;
  background-color: var(--color-border-light);
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 18px;
  padding: 0;
}

.styles_expandButton__9PoVW > * {
  color: var(--color-border-main);
}

.styles_unreadCount__eqPIk {
  display: inline-block;
  background: var(--color-secondary-light);
  border-radius: 6px;
  margin-left: 9px;
  color: var(--color-static-main);
  text-align: center;
  width: 18px;
  height: 18px;
}

.styles_container__de6sc {
  height: var(--header-inner-height);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  border-radius: 0 !important;
  background-color: var(--color-background-paper);
  border-bottom: 1px solid var(--color-border-light);
}

.styles_element__TDva3 {
  padding: 0 var(--space-2);
  height: 100%;
  border-right: 1px solid var(--color-border-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.styles_menuButton__0CWSn,
.styles_logo__Oax41 {
  flex: 1 1;
  border: none;
  align-items: flex-start;
}

.styles_logo__Oax41 svg {
  width: auto;
  display: block;
  color: var(--color-logo-main);
}

.styles_menuButton__0CWSn {
  display: none;
}

.styles_networkSelector__VOK9j {
  padding-right: 0;
  padding-left: 0;
  border-right: none;
}

@media (max-width: 900px) {
  .styles_logo__Oax41 {
    display: none;
  }

  .styles_menuButton__0CWSn {
    display: flex;
  }
}

@media (max-width: 600px) {
  .styles_element__TDva3 {
    padding: 0 var(--space-1);
  }

  .styles_menuButton__0CWSn {
    padding-left: var(--space-2);
  }

  .styles_networkSelector__VOK9j {
    padding-right: 0;
  }

  .styles_hideMobile__txkpm {
    display: none;
  }
}

.styles_container__Dir6G {
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1 1;
}

.styles_container__BERqM {
  padding: var(--space-2);
  font-size: 13px;
}

.styles_container__BERqM ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  row-gap: 0.2em;
  column-gap: var(--space-2);
  align-items: center;
}

.styles_container__BERqM li {
  padding: 0;
  margin: 0;
}

.styles_container__BERqM li:not(:last-of-type):after {
  content: '|';
  margin-left: var(--space-2);
}

@media (max-width: 600px) {
  .styles_container__BERqM li:not(:last-of-type):after {
    visibility: hidden;
  }
}

.styles_container__sVNFX {
  position: relative;
}

.styles_threshold__NXjVW {
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 1;
  border-radius: 100%;
  font-size: 12px;
  min-width: 24px;
  min-height: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  font-weight: 700;
  background-color: var(--color-secondary-light);
}

.styles_button__adDa3 {
  height: 38px;
}

.styles_container__3UrQA {
  padding: var(--space-2) var(--space-1);
}

.styles_info__F7k0B {
  padding: 0 var(--space-1);
}

.styles_safe__UqU08 {
  display: flex;
  gap: 12px;
  text-align: left;
  align-items: center;
}

.styles_iconButtons__JxTuK {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
}

.styles_iconButton__a7Xbc {
  border-radius: 4px;
  padding: 6px;
  background-color: var(--color-background-main);
  width: 32px;
  height: 32px;
}

.styles_iconButton__a7Xbc:hover {
  background-color: var(--color-secondary-background);
}

.styles_address__kH8N_ {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.styles_addButton__qH2JS {
  border-radius: 8px;
  padding: 8px 10px;
}

.styles_addButton__qH2JS:hover {
  background-color: var(--color-secondary-background);
}

.styles_dialog__Kg__C .MuiDialogActions-root {
  border-top: 2px solid var(--color-border-light);
  padding: var(--space-3);
}

.styles_dialog__Kg__C .MuiDialogActions-root > :last-of-type:not(:first-of-type) {
  order: 2;
}

.styles_dialog__Kg__C .MuiDialogActions-root:after {
  content: '';
  order: 1;
  flex: 1 1;
}

.styles_dialog__Kg__C .MuiDialogTitle-root {
  border-bottom: 2px solid var(--color-border-light);
}

@media (min-width: 600px) {
  .styles_dialog__Kg__C .MuiDialog-paper {
    min-width: 600px;
    margin: 0;
  }
}

.styles_menu__fVenh .MuiPaper-root {
  border-radius: 8px !important;
}

.styles_menu__fVenh .MuiList-root {
  padding: 4px;
}

.styles_menu__fVenh .MuiMenuItem-root {
  padding-left: 12px;
  min-height: 40px;
  border-radius: 8px !important;
}

.styles_menu__fVenh .MuiMenuItem-root:hover {
  background-color: var(--color-secondary-background);
}

.styles_menu__fVenh .MuiListItemIcon-root {
  min-width: 26px;
}

.styles_container__lzgYC {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.styles_safe__XVRlq {
  border-radius: 8px;
}

.styles_open__XweTv {
  padding-left: 16px;
  border-left: 6px solid var(--color-secondary-light);
  background-color: var(--color-secondary-background) !important;
}

.styles_safe__XVRlq:hover {
  background-color: var(--color-secondary-background);
}

.styles_container__SYUem {
  overflow-y: auto;
}

.styles_header__zT_9Y {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-3) var(--space-3) var(--space-2);
  border-bottom: 1px solid var(--color-border-light);
}

.styles_chainDivider___Nl5b {
  width: unset;
  border-radius: 8px;
  text-transform: none;
  height: 27px;
  margin: var(--space-2);
}

.styles_ownedLabelWrapper__uKQAq {
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_ownedLabel__umjeT {
  cursor: pointer;
  color: var(--color-primary-light);
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
}

.styles_list__5bQAD {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 0;
}

.styles_list__tj2RO {
  display: grid;
  grid-gap: 4px;
  gap: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.styles_icon__neKD9 {
  min-width: 0;
  margin-right: var(--space-2);
}

.styles_listItemButton__MvBS9 {
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.styles_list__tj2RO .MuiListItemButton-root {
  color: var(--color-text-primary);
}

[data-theme='dark'] .styles_list__tj2RO .Mui-selected {
  background-color: var(--color-border-light);
}

.styles_list__tj2RO .MuiListItemButton-root:hover {
  border-radius: 6px;
  background-color: var(--color-background-light);
}

.styles_list__tj2RO .Mui-selected {
  border-radius: 6px;
  background-color: var(--color-background-main);
}

.styles_listItemButton__MvBS9 .beamer_icon.active {
  top: auto;
  left: 28px;
  bottom: 10px;
  width: 6px;
  height: 6px;
  color: transparent;
}

.styles_container__56gzw,
.styles_drawer__rnp9k {
  height: 100vh;
  padding-top: var(--header-height);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--color-background-paper);
}

.styles_container__56gzw {
  width: 230px;
  border-right: 1px solid var(--color-border-light);
}

.styles_scroll__g_tfc {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.styles_drawer__rnp9k {
  width: 400px;
  max-width: 90vw;
}

.styles_drawer__rnp9k {
  text-align: center;
  padding: var(--header-height) 0 0 0;
  border-right: 1px solid var(--color-border-light);
}

.styles_noSafeHeader__MXLGL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 100px;
}

.styles_drawerButton__LT8kk {
  position: absolute !important;
  z-index: 2;
  right: 0;
  transform: translateX(50%);
  margin-top: 54px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;
  background-color: var(--color-background-main);
}

.styles_drawerButton__LT8kk:hover {
  background-color: var(--color-secondary-background);
}

.styles_drawerButton__LT8kk svg {
  transform: translateX(-25%);
}

@media (max-width: 900px) {
  .styles_container__56gzw {
    padding-top: var(--header-height);
  }

  .styles_drawer__rnp9k {
    max-width: 90vw;
  }

  .styles_drawerButton__LT8kk {
    width: 60px;
    height: 60px;
    margin-top: 44px;
  }
}

.styles_header__zIkHe {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1201;
}

.styles_main__QA8fD {
  background-color: var(--color-background-main);
  padding-left: 230px;
  padding-top: var(--header-height);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.styles_mainNoSidebar__OAXg0 {
  padding-left: 0;
}

.styles_content___WFFC {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.styles_content___WFFC main {
  padding: var(--space-3);
}

.styles_sidebarTogglePosition__7SY_q {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  /* mimics MUI drawer animation */
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.styles_sidebarTogglePosition__7SY_q.styles_sidebarOpen__M5FXY {
  transform: translateX(230px);
}

.styles_sidebarToggle__StJKw {
  height: 100vh;
  width: var(--space-1);
  background-color: var(--color-border-light);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}

.styles_sidebarToggle__StJKw button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -3px;
  transform: translateY(-50%);
  background-color: var(--color-border-light);
  -webkit-clip-path: inset(0 -14px 0 0);
          clip-path: inset(0 -14px 0 0);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.styles_sidebarToggle__StJKw:hover,
.styles_sidebarToggle__StJKw:hover button {
  background-color: var(--color-background-light);
}

@media (max-width: 900px) {
  .styles_main__QA8fD {
    padding-left: 0;
  }
}

@media (max-width: 600px) {
  .styles_main__QA8fD main {
    padding: var(--space-2);
  }
}

.PsaBanner_banner__cjHwW {
  background-color: var(--color-info-dark);
  color: var(--color-text-light);
  padding: 0 var(--space-3);
  font-size: 16px;
}

.PsaBanner_banner__cjHwW a {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}

.PsaBanner_wrapper__k_y4G {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--header-inner-height);
}

.PsaBanner_content__g0x2L {
  max-width: 960px;
  text-align: center;
}

.PsaBanner_close__ePiVH {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 2;
}

@media (max-width: 900px) {
  .PsaBanner_close__ePiVH {
    position: relative;
    right: unset;
    top: unset;
  }
}

@media (max-width: 600px) {
  .PsaBanner_banner__cjHwW {
    padding: var(--space-1) var(--space-2);
  }
}

.styles_container__12f0T {
  padding: var(--space-3) var(--space-4);
  position: fixed;
  z-index: 1300;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0 !important;
}

.styles_grid__rlTfD {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-1);
  justify-content: center;
}

.styles_container__12f0T form {
  margin-top: var(--space-2);
}

.styles_container__12f0T label,
.styles_container__12f0T input {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

@media (max-width: 600px) {
  .styles_container__12f0T {
    padding: var(--space-3) var(--space-1);
  }
}

.styles_container__ln8bt {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.styles_wrapper__hMqAD {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  text-align: center;
  padding: var(--space-2);
}

